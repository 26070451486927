import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { graphql } from "gatsby"
import { window } from "browser-monads"
import scrollTo from "gatsby-plugin-smoothscroll"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Icons
import QuestionMarkIcon from "../assets/icons/faq-question-mark.svg"
import SecurityIcon from "../assets/icons/faq-shield-secure.svg"
import ComplianceIcon from "../assets/icons/faq-compliance.svg"
import CardIcon from "../assets/icons/faq-card.svg"
import WalletIcon from "../assets/icons/faq-payout-wallet.svg"
import PaymentLinksIcon from "../assets/icons/faq-payment-links.svg"
import DashboardIcon from "../assets/icons/faq-dashboard.svg"
import PuzzleIcon from "../assets/icons/faq-modules-puzzle.svg"

// Images
import TwistedShapesLeft from "../assets/faq-twisted-shapes-left.svg"
import TwistedShapesRight from "../assets/faq-twisted-shapes-right.svg"
import TwistedShapeMobile from "../assets/faq-twisted-shape-mobile.svg"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Styles
import "../styles/pages/faq.scss"

const HeroSection = () => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero">
      <div className="container">
        <div
          style={{ width: 50, height: 50, margin: "auto" }}
          className="has-margin-bottom-4"
        >
          <QuestionMarkIcon />
        </div>
        <h1 className="title is-spaced has-margin-bottom-3 has-text-centered has-text-deep-dark-blue">
          {t("Frequently Asked Questions")}
        </h1>
        <h2
          style={{ maxWidth: 500, margin: "auto" }}
          className="subtitle is-6 has-margin-bottom-6 has-text-centered"
        >
          {t(
            "A list of questions and answers commonly asked by our users and generally addressing Twispay features and processes."
          )}
        </h2>
        <div className="mobile-twisted-shape is-hidden-tablet">
          <img src={TwistedShapeMobile} alt="Twisted Shape" />
        </div>
      </div>
    </section>
  )
}
const ListSection = ({ categories }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(
    window.location.hash.split("#")[1]
  )
  useEffect(() => {
    if (window.location.hash)
      setTimeout(() => scrollTo(window.location.hash), 500)
  }, [])
  useEffect(() => {
    // handleOpenQuestion(window.location.hash)
    const url = window.location.hash
    if (window.location.hash && selectedQuestion !== url) {
      setSelectedQuestion(window.location.hash.split("#")[1])
    }
  }, [selectedQuestion])
  function handleOpenQuestion(url) {
    if (selectedQuestion !== url) {
      setSelectedQuestion(url)
      if (window.history.pushState) {
        window.history.pushState(null, null, `#${url}`)
      } else {
        window.location.hash = `#${url}`
      }
    }
  }

  const icons = {
    security: <SecurityIcon />,
    compliance: <ComplianceIcon />,
    activation: <CardIcon />,
    payout: <WalletIcon />,
    "payment-links": <PaymentLinksIcon />,
    dashboard: <DashboardIcon />,
    "payment-modules": <PuzzleIcon />,
  }

  return (
    <section className="section list-section">
      <div className="container">
        <div className="columns ">
          <div className="column is-8 is-offset-1">
            {Object.keys(categories).map((category, i) => (
              <div
                key={category}
                className={`${
                  i === 0 ? "" : "has-margin-top-6  has-margin-top-0-mobile"
                } has-margin-bottom-6 has-margin-bottom-0-mobile columns`}
              >
                <div className="column is-1 has-text-centered is-hidden-mobile">
                  <span
                    style={{ width: 48, height: 48, marginTop: -18 }}
                    className="icon"
                  >
                    {icons[categories[category].icon]}
                  </span>
                </div>
                <div className="column">
                  <div>
                    <h2
                      className="title is-uppercase is-flex is-vcentered is-hspaced section-title"
                      id={category}
                    >
                      <span
                        style={{ width: 56, height: 56 }}
                        className="icon has-margin-right-3 is-hidden-tablet"
                      >
                        {icons[categories[category].icon]}
                      </span>
                      {categories[category].title}
                    </h2>
                    {categories[category].QAs.map((QA, i) => (
                      <div key={QA.url} className="question-wrapper">
                        <button
                          id={QA.url}
                          onClick={() => handleOpenQuestion(QA.url)}
                          className={`${
                            selectedQuestion === QA.url ? "is-selected" : ""
                          } has-text-left is-paddingless has-margin-bottom-4 question`}
                        >
                          {QA.question}
                        </button>
                        <AnimatePresence
                          onExitComplete={() =>
                            scrollTo(`#${selectedQuestion}`)
                          }
                        >
                          {selectedQuestion === QA.url && (
                            <motion.p
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{
                                x: {
                                  type: "spring",
                                  stiffness: 300,
                                  damping: 200,
                                },
                                opacity: { duration: 0.2 },
                              }}
                              className="answer is-size-6 is-pre-line has-margin-bottom-5"
                              dangerouslySetInnerHTML={{ __html: QA.answer }}
                            />
                          )}
                        </AnimatePresence>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
const FAQPage = ({ data }) => {
  return (
    <Layout
      stripeColor="#f5f8ff"
      pageName="faq"
      hasLightBackground
      TwistedShapesLeft={TwistedShapesLeft}
      TwistedShapesRight={TwistedShapesRight}
    >
      <SEO title="Frequently Asked Questions" />
      <HeroSection />

      <ListSection categories={data.markdownRemark.frontmatter.categories} />
    </Layout>
  )
}

export default FAQPage

export const pageQuery = graphql`
  query($originalPath: String!, $locale: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $originalPath }, locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        path
        title
        subtitle
        categories {
          title
          icon
          QAs {
            question
            answer
            url
          }
        }
      }
    }
  }
`
